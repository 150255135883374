import React, { useState } from 'react';
import CircusMenu from '../CircusMenu/CircusMenu';
import Circus2015View from './Circus2015View/Circus2015View';
import Circus2016View from './Circus2016View/Circus2016View';
import Circus2017View from './Circus2017View/Circus2017View';
import Circus2018View from './Circus2018View/Circus2018View';
import Circus2019View from './Circus2019View/Circus2019View';
import Circus2020View from './Circus2020View/Circus2020View';
import Circus2021View from './Circus2021View/Circus2021View';
import Circus2022View from './Circus2022View/Circus2022View';
import Circus2023View from './Circus2023View/Circus2023View';
import styles from './CircusView.module.scss';

export interface CircusMenuItem {
    id: number,
    name: string,
    component?: any
    link?: string
}

export default function CircusView() {

    const items: CircusMenuItem[] = [
        {
            id: 1,
            name: "2023",
            component: <Circus2023View />
        },
        {
            id: 2,
            name: "Bandas Sonoras",
            link: "https://bsocirco.coliseu.pt/"
        },
        {
            id: 3,
            name: "2022",
            component: <Circus2022View />
        },
        {
            id: 4,
            name: "2021",
            component: <Circus2021View />
        },
        {
            id: 5,
            name: "2020",
            component: <Circus2020View />
        },
        {
            id: 6,
            name: "2019",
            component: <Circus2019View />
        },
        {
            id: 7,
            name: "2018",
            component: <Circus2018View />
        },
        {
            id: 8,
            name: "FICPORTO 2018",
            link: "https://circo.coliseu.pt/"
        },
        {
            id: 9,
            name: "2017",
            component: <Circus2017View />
        },
        {
            id: 10,
            name: "2016",
            component: <Circus2016View />
        },
        {
            id: 11,
            name: "2015",
            component: <Circus2015View />
        }
    ]

    const [item, setItem] = useState(items[0])

    return (
        <div className={styles.Wrapper}>
            <CircusMenu items={items} selected={item} select={setItem} />
            {item.component}
        </div>
    )
}
